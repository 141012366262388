.listWrap .el-icon-arrow-down {
  font-size: 12px;
}
.listWrap .el-icon-arrow-down:before {
  content: "\e6df" !important;
}
.listWrap .el-tabs {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.listWrap .el-tabs .el-tabs__content {
  flex: 1;
}
.Classitem .el-form-item__content {
  display: flex;
}
.el-button.is-disabled,
.el-button.is-disabled:focus,
.el-button.is-disabled:hover {
  background-color: transparent;
}
.confirmButtonClass {
  color: #fff !important;
  background-color: #5c6be8 !important;
  border-color: #5c6be8 !important;
}
.btnBox {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}
.exportdialog .el-dialog__title {
  font-size: 16px;
}
.exportdialog .export-box {
  width: 100%;
  margin-bottom: 20px;
}
.exportdialog .export-box h1 {
  padding: 0 5px 10px;
  display: flex;
  align-items: flex-end;
  font-size: 40px;
  border-bottom: 1px dashed #ccc;
}
.exportdialog .export-box h1 span {
  margin-left: 20px;
  font-size: 14px;
}
.exportdialog .export-box > div {
  padding-left: 30px;
}
.exportdialog .export-box .el-button.is-disabled,
.exportdialog .export-box .el-button.is-disabled:focus,
.exportdialog .export-box .el-button.is-disabled:hover {
  color: #fff;
  background: #c3c3c3;
  border: none;
}
.docsDialog .el-form-item {
  margin-bottom: 0.5rem;
}
.studentNum {
  margin: 20px 0 10px;
}
.studentNum span {
  margin: 0 10px;
}
.exportdialog /deep/ .upload-workers {
  height: 30px;
}
.exportdialog /deep/ .upload-workers .el-upload {
  height: 30px !important;
  width: 97px;
  border: none !important;
}
.exportdialog /deep/ .upload-workers .el-upload .el-button {
  padding: 5px 0;
}
