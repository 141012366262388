


























































































































































































































































































































































































































































































































































































.searchbox>div .el-input {
  flex: 1;
}

.searchBox {
  padding: 0;

  .el-icon-arrow-down:before {
    content: "\e6df" !important;
  }
}

.searchboxItems {
  display: flex;
  align-items: center;

  .itemLabel {
    min-width: 5rem;
    text-align: right;
    font-size: 14px;
    padding: 0 0.5rem;
  }
}

.operationControlCurr {
  >div {
    width: 100%;
    justify-content: space-between;
  }

  .itemLabel {
    min-width: 5rem;
    text-align: right;
    font-size: 14px;
    padding: 0 0.5rem;
  }
}
